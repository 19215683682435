// define a mixin object
import {log} from "@/shared/utils";
import { getReportAdvertsPublished, getReportSoMesCreated, getReportCaseLoadBusinessUnit, getReportCaseLoadUser, getBusinessUnits } from "@/shared/dataservice"
import {REPORT_CSV_MAX_ROWS} from "@/shared/consts";

export const reportActionsMixin = {
    data() {
        return {

        }
    },
    methods: {
        onReportDownloadClick(e, type){
            e.preventDefault();
            log(["click trigger download", type]);
            this.downloadReportCSV(type);
        },
        async downloadReportCSV(type) {
            log(["trigger download", type]);

            let methodName = "";

            if(type === 'totalAdvertsPublished'){
                methodName = "loadReportAdvertsPublished";
            }
            else if (type === 'totalSoMesCreated') {
                methodName = "loadReportSoMesCreated"
            }
            else if(type === 'userCaseLoad'){
                methodName = "loadReportUserCaseLoad";
            }
            else if(type === 'businessUnitCaseLoad'){
                methodName = "loadReportBusinessUnitCaseLoad";
            }

            await this[methodName](REPORT_CSV_MAX_ROWS, true)
                .then(response => {
                    log(["Response", response]);
                    return response.data;
                })
                .then(blobby => {
                    let objectUrl = window.URL.createObjectURL(blobby);
                    const anchor = document.createElement('a');

                    anchor.setAttribute('id', 'deleteMe');
                    anchor.href = objectUrl;
                    anchor.setAttribute('download', `${type}.csv`);
                    document.body.appendChild(anchor);
                    anchor.click();
                    anchor.remove();
                });
        },
        async getTotalAdvertsPublishedGraphData(businessUnitFilter = false){

            let params = { 'Page' : 1, 'ItemsPerPage' : 12};

            if(businessUnitFilter){
                params.businessUnitFilter = businessUnitFilter;
            }

            let result = await this.loadReportAdvertsPublished(params, false);

            this.totalAdvertsPublishedGraphData = result.data.data

            log(["Get total adverts published data", this.totalAdvertsPublishedGraphData]);

            if(this.totalAdvertsPublishedGraphData){
                this.showTotalAdvertsPublishedGraph = true;
            }
            else
            {
                this.showTotalAdvertsPublishedGraph = false;
            }
        },
        async getTotalSoMesCreatedGraphData(businessUnitFilter = false) {

            let params = { 'Page': 1, 'ItemsPerPage': 12 }

            if (businessUnitFilter) {
                params.businessUnitFilter = businessUnitFilter
            }

            let result = await this.loadReportSoMesCreated(params, false)

            this.totalSoMesCreatedGraphData = result.data.data

            log(["Get total soMe created data", this.totalSoMesCreatedGraphData])

            if (this.totalSoMesCreatedGraphData) {
                this.showTotalSoMesCreatedGraph = true
            }
            else {
                this.showTotalSoMesCreatedGraph = false
            }
        },
        async loadReportAdvertsPublished(params, forceDownload = false) {
            let result = await getReportAdvertsPublished(params, forceDownload);

            log(["Loaded report adverts published", result, forceDownload]);

            if (result.status === 200) {
                return result
            }
            else {
                return false
            }
        },
        async loadReportSoMesCreated(params, forceDownload = false) {
            let result = await getReportSoMesCreated(params, forceDownload)

            log(["Loaded report SoMe createdd", result, forceDownload]);

            if(result.status === 200){
                return result;
            }
            else
            {
                return false;
            }
        },
        async loadReportUserCaseLoad(params, forceDownload = false) {
            let result = await getReportCaseLoadUser(params, forceDownload);

            log(["Loaded report adverts published", params, forceDownload]);

            if(result.status === 200){
                return result;
            }
            else
            {
                return false;
            }
        },
        async loadReportBusinessUnitCaseLoad(params, forceDownload = false) {
            let result = await getReportCaseLoadBusinessUnit(params, forceDownload);

            log(["Loaded report adverts published", result, forceDownload]);

            if(result.status === 200){
                return result;
            }
            else
            {
                return false;
            }
        },
        async loadBusinessUnits() {
            let result = await getBusinessUnits();
            log(["Got advert business units", result]);
            return result;
        },
    },
}